import * as React from "react";
import styled from "styled-components";

import { fontTypes, breakpoints } from "../styles/variables";
import { Container } from "./content";
import { PartnerType } from "../types";

const StyledPartner = styled(Container)`
    margin: 50px auto 0 auto;
    text-align: center;

    @media (min-width: ${breakpoints.lg}px) {
      margin: 70px auto 0 auto;
    }

    img {
        margin: 0 20px;
    }
`;

const Title = styled.h5`
    ${fontTypes.h5};

    @media (min-width: ${breakpoints.lg}px) {
        ${fontTypes.h5Desktop}
    }
`;

interface PartnerProps {
    data: PartnerType[]
}

const Partner: React.FC<PartnerProps> = ({ data }) => {
    if(data.length) {
        return (
            <StyledPartner>
              <Title>Projektpartner</Title>
              { data.map((partner) => (
                  <a href={partner.link} target="_blank">
                      <img src={partner.image.publicURL}/>
                  </a>
              ))}
            </StyledPartner>
        )
    }
    return null;
};

export default Partner;
