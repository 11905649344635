import * as React from "react";
import { graphql } from "gatsby"

import Page from "../components/Page";
import { MiddleContainer } from "../components/content";
import DefaultLayout from "../layouts/default";
import Spacer from "../components/Spacer";
import Partner from "../components/Partner";

import { ProjectType } from "../types";
import { MenuProvider } from '../hooks/Menu/Provider';

import Markdown from 'react-remarkable';

export const query = graphql`
  query($slug: String!) {
    project: projectsJson(fields: {slug: { eq:$slug } }) {
      id
      name
      name_short
      lead
      quote
      text
      slug
      tags {
        id
        name
        slug
      }
      year
      creation
      image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      partner {
        id
        name
        slug
        link
        image {
          publicURL
        }
        image_negative{
          publicURL
        }
      }
    }
  }
`

interface ProjectProps {
  data: {
    project: ProjectType
  };
}

const ProjectTemplate: React.FC<ProjectProps> = ({ data }) => {
    return (
      <MenuProvider>
        <DefaultLayout 
          title={data.project.name} 
          subtitle={[data.project.lead]} 
          backgroundImage={data.project.image.childImageSharp}
          tags={data.project.tags}
        >
          <Page>
            <MiddleContainer serifFont={true} initialLetter={true}>
              <Markdown source={data.project.quote}/>
            </MiddleContainer>
            <Spacer/>
            <MiddleContainer>
              <Markdown source={data.project.text}/>
            </MiddleContainer>
            <Partner data={data.project.partner}/>
          </Page>
        </DefaultLayout>
      </MenuProvider>
    )
  };
  
  export default ProjectTemplate;