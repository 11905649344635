import * as React from "react";
import styled from "styled-components";

import { colors, breakpoints } from "../styles/variables";
import { Container } from "./content";

const StyledSpacer = styled(Container)`
    margin: 50px auto;

    @media (min-width: ${breakpoints.lg}px) {
      margin: 70px auto;
    }
`;

const SpacerInner = styled.div`
    margin: 0 auto;
    left: calc(50% - 50px);
    width: 100px;
    height: 2px;
    background-color: ${colors.middle};
    border-radius: 2px;

    @media (min-width: ${breakpoints.lg}px) {
      left: calc(50% - 75px);
      width: 150px;
    }
`;


const Spacer: React.FC = () => (
  <StyledSpacer><SpacerInner/></StyledSpacer>
);

export default Spacer;
